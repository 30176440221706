@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
}

html::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #f2f4f8;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  display: block;
  color: #000000;
}

button {
  outline: none;
  border: none;
}

.input,
.passwordInput,
.emailInput,
.nameInput,
.textarea {
  box-shadow: rgba(0, 0, 0, 0.11);
  border: none;
  background: #ffffff;
  border-radius: 3rem;
  height: 3rem;
  width: 100%;
  outline: none;
  font-family: 'Montserrat', sans-serif;
  padding: 0 3rem;
  font-size: 1rem;
}

@media (min-width: 1100px) {
  .input,
  .passwordInput,
  .emailInput,
  .nameInput,
  .textarea {
    padding: 0 5rem;
  }
}

.textarea {
  padding: 1rem 1.5rem;
  height: 300px;
  border-radius: 1rem;
}

.primaryButton {
  cursor: pointer;
  background: #00cc66;
  border-radius: 1rem;
  padding: 0.85rem 2rem;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.25rem;
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.removeIcon {
  cursor: pointer;
  position: absolute;
  top: -3%;
  right: -2%;
}

.editIcon {
  cursor: pointer;
  position: absolute;
  top: -3.4%;
  right: 20px;
}

.pageContainer,
.offers,
.profile,
.listingDetails,
.category,
.explore {
  margin: 1rem;
}

@media (min-width: 1024px) {
  .pageContainer,
  .offers,
  .profile,
  .listingDetails,
  .category,
  .explore {
    margin: 3rem;
  }
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #00cc66 transparent #00cc66 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pageHeader {
  font-size: 2rem;
  font-weight: 800;
}

.navbar {
  position: fixed; /* Fixed position to anchor it to the viewport */
  left: 0;
  bottom: 0;
  right: 0;
  height: 85px;
  background-color: #ffffff;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better separation */
}

.navbarNav {
  width: 100%;
  margin-top: 0; /* Ensure no margin pushes it upwards */
  overflow-y: hidden;
}

.navbarListItems {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navbarListItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbarListItemName,
.navbarListItemNameActive {
  margin-top: 0.25rem;
  font-size: 14px;
  font-weight: 600;
  color: #8f8f8f;
}

.navbarListItemNameActive {
  color: #2c2c2c;
}

/* Ensure no extra space at the bottom of the content */
body,
html {
  margin: 0;
  padding: 0;
  min-height: 100vh; /* Ensure the page content spans full height */
  display: flex;
  flex-direction: column;
}

main {
  flex: 1; /* Allow main content to fill available space above the navbar */
  padding-bottom: 85px; /* Prevent content from being hidden behind the navbar */
}

.nameInput {
  margin-bottom: 2rem;
  background: url('./assets/svg/badgeIcon.svg') #ffffff 2.5% center no-repeat;
}

.emailInput {
  margin-bottom: 2rem;
  background: url('./assets/svg/personIcon.svg') #ffffff 2.5% center no-repeat;
}

.passwordInputDiv {
  position: relative;
}

.passwordInput {
  margin-bottom: 2rem;
  background: url('./assets/svg/lockIcon.svg') #ffffff 2.5% center no-repeat;
}

.showPassword {
  cursor: pointer;
  position: absolute;
  top: -4%;
  right: 1%;
  padding: 1rem;
}

.forgotPasswordLink {
  cursor: pointer;
  color: #00cc66;
  font-weight: 600;
  text-align: right;
}

.signInBar,
.signUpBar {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: inherit;
}

.signInButton,
.signUpButton,
.signInText,
.signUpText {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .signInBar,
  .signUpBar {
    justify-content: start;
  }
}

.signInText,
.signUpText {
  font-size: 1.5rem;
  font-weight: 700;
}

.signInButton,
.signUpButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  background-color: #00cc66;
  border-radius: 50%;
}

@media (min-width: 1024px) {
  .signInButton,
  .signUpButton {
    margin-left: 3rem;
  }
}

.socialLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialIconDiv {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 3rem;
  width: 3rem;
  height: 3rem;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.socialIconImg {
  width: 100%;
}

.registerLink {
  margin-top: 4rem;
  color: #00cc66;
  font-weight: 600;
  text-align: center;
  margin-bottom: 3rem;
}

@media (min-width: 1217px) {
  .explore {
    margin-bottom: 10rem;
  }
}

@media (max-height: 536) {
  .explore {
    margin-bottom: 10rem;
  }
}

.exploreHeading,
.exploreCategoryHeading {
  font-weight: 700;
}

.exploreCategoryHeading {
  margin-top: 3rem;
}

.swiper-container {
  min-height: 225px;
  height: 23vw;
}

.swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}

.swiperSlideDiv {
  position: relative;
  width: 100%;
  height: 100%;
}

.swiperSlideImg {
  width: 100%;
  object-fit: cover;
}

.swiperSlideText {
  color: #ffffff;
  position: absolute;
  top: 70px;
  left: 0;
  font-weight: 600;
  max-width: 90%;
  font-size: 1.25rem;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5rem;
}

@media (min-width: 1024px) {
  .swiperSlideText {
    font-size: 1.75rem;
  }
}

.swiperSlidePrice {
  color: #000000;
  position: absolute;
  top: 143px;
  left: 11px;
  font-weight: 600;
  max-width: 90%;
  background-color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
}

@media (min-width: 1024px) {
  .swiperSlidePrice {
    font-size: 1.25rem;
  }
}

.exploreCategories {
  display: flex;
  justify-content: space-around;
}

.exploreCategories a {
  width: 60%;
}

.exploreCategoryImg {
  min-height: 115px;
  height: 15vw;
  width: 100%;
  border-radius: 1.5rem;
  object-fit: cover;
  margin: 0 auto;
}

.exploreCategoryName {
  font-weight: 500;
  text-align: left;
}

.category {
  margin-bottom: 10rem;
}

.categoryListings {
  padding: 0;
}

.categoryListing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
}

.categoryListingLink {
  display: contents;
}

.categoryListingImg {
  width: 30%;
  height: 100px;
  border-radius: 1.5rem;
  object-fit: cover;
}

@media (min-width: 1024px) {
  .categoryListingImg {
    width: 19%;
    height: 217px;
  }
}

.categoryListingDetails {
  width: 65%;
}

@media (min-width: 1024px) {
  .categoryListingDetails {
    width: 79%;
  }
}

.categoryListingLocation {
  font-weight: 600;
  font-size: 0.7rem;
  opacity: 0.8;
  margin-bottom: 0;
}

.categoryListingName {
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0;
}

.categoryListingPrice {
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 1.1rem;
  color: #00cc66;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.categoryListingInfoDiv {
  display: flex;
  justify-content: space-between;
  max-width: 275px;
}

.categoryListingInfoText {
  font-weight: 500;
  font-size: 0.7rem;
}

.loadMore {
  cursor: pointer;
  width: 8rem;
  margin: 0 auto;
  text-align: center;
  padding: 0.25rem 0.5rem;
  background-color: #000000;
  color: #ffffff;
  font-weight: 600;
  border-radius: 1rem;
  opacity: 0.7;
  margin-top: 2rem;
}

.listingDetails {
  margin-bottom: 10rem;
}

.shareIconDiv {
  cursor: pointer;
  position: fixed;
  top: 3%;
  right: 5%;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listingName {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.listingLocation {
  margin-top: 0;
  font-weight: 600;
}

.discountPrice {
  padding: 0.25rem 0.5rem;
  background-color: #000000;
  color: #ffffff;
  border-radius: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  display: inline;
}

.listingType {
  padding: 0.25rem 0.5rem;
  background-color: #00cc66;
  color: #ffffff;
  border-radius: 2rem;
  display: inline;
  font-weight: 600;
  font-size: 0.8rem;
  margin-right: 1rem;
}

.listingDetailsList {
  padding: 0;
  list-style-type: none;
}

.listingDetailsList li {
  margin: 0.3rem 0;
  font-weight: 500;
  opacity: 0.8;
}

.listingLocationTitle {
  margin-top: 2rem;
  font-weight: 600;
  font-size: 1.25rem;
}

.leafletContainer {
  width: 100%;
  height: 200px;
  overflow-x: hidden;
  margin-bottom: 3rem;
}

@media (min-width: 1024px) {
  .leafletContainer {
    height: 400px;
  }
}

.linkCopied {
  position: fixed;
  top: 9%;
  right: 5%;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
}

.contactListingName {
  margin-top: -1rem;
  margin-bottom: 0;
  font-weight: 600;
}

.contactListingLocation {
  margin-top: 0.25rem;
  font-weight: 600;
}

.contactLandlord {
  margin-top: 2rem;
  display: flex;
  align-items: center;
}

.landlordName {
  font-weight: 600;
  font-size: 1.2rem;
}

.messageForm {
  margin-top: 0.5rem;
}

.messageDiv {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}

.messageLabel {
  margin-bottom: 0.5rem;
}

.profile {
  margin-bottom: 10rem;
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logOut {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #00cc66;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
}

.profileDetailsHeader {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
}

.personalDetailsText {
  font-weight: 600;
}

.changePersonalDetails {
  cursor: pointer;
  font-weight: 600;
  color: #00cc66;
}

.profileCard {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.2);
  max-width: 500px;
}

.profileDetails {
  display: flex;
  flex-direction: column;
}

.profileName,
.profileEmail,
.profileAddress,
.profileAddressActive,
.profileEmailActive,
.profileNameActive {
  all: unset;
  margin: 0.3rem 0;
  font-weight: 600;
  width: 100%;
}

.profileNameActive {
  background-color: rgba(44, 44, 44, 0.1);
}

.profileEmail,
.profileAddress,
.profileAddressActive,
.profileEmailActive {
  font-weight: 500;
}

.profileEmailActive {
  background-color: rgba(44, 44, 44, 0.1);
}

.profileAddressActive {
  background-color: rgba(44, 44, 44, 0.1);
}

.createListing {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0.25rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
  font-weight: 600;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listingText {
  margin-top: 3rem;
  font-weight: 600;
}

.lisitingsList {
  padding: 0;
}

.formLabel {
  font-weight: 600;
  margin-top: 1rem;
  display: block;
}

.formButtons {
  display: flex;
}

.formButton,
.formInput,
.formInputAddress,
.formInputName,
.formInputSmall,
.formInputFile,
.formButtonActive {
  padding: 0.9rem 3rem;
  background-color: #ffffff;
  font-weight: 600;
  border-radius: 1rem;
  font-size: 1rem;
  margin: 0.5rem 0.5rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formButtonActive {
  background-color: #00cc66;
  color: #ffffff;
}

.flex {
  display: flex;
}

.formInput,
.formInputAddress,
.formInputName,
.formInputSmall,
.formInputFile {
  border: none;
  outline: none;
  font-family: 'Montserrat', sans-serif;
}

.formInputSmall,
.formInputFile {
  margin-right: 3rem;
  padding: 0.9rem 0.7rem;
  text-align: center;
}

.formInputName {
  padding: 0.9rem 1rem;
  width: 90%;
  max-width: 326px;
}

.formInputAddress {
  padding: 0.9rem 1rem;
  width: 90%;
  max-width: 326px;
}

.formPriceDiv {
  display: flex;
  align-items: center;
}

.formPriceText {
  margin-left: -1.5rem;
  font-weight: 600;
}

.imagesInfo {
  font-size: 0.9rem;
  opacity: 0.75;
}

.formInputFile {
  width: 100%;
}

.formInputFile::-webkit-file-upload-button {
  background-color: #00cc66;
  border: none;
  color: #ffffff;
  font-weight: 600;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  margin-right: 1rem;
}

.createListingButton {
  margin-top: 5rem;
}

.offers {
  margin-bottom: 10rem;
}

.offerBadge {
  padding: 0.25rem 0.5rem;
  background-color: #000000;
  color: #ffffff;
  border-radius: 1rem;
  margin-left: 1rem;
  font-size: 0.8rem;
  opacity: 0.75;
}
.categoryListingPrice {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.originalPrice {
  color: #999;
  text-decoration: line-through;
  margin-right: 0.5rem;
}

.discountedPrice {
  color: #00cc66;
  margin-right: 0.5rem;
}

.discountAmount {
  color: #00cc66;
  font-size: 0.9rem;
}

.pageContainer {
  width: 100%;
  max-width: 400px;
  margin: 25px auto;
  padding: 20px;
}

.pageHeader {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.inputField {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.inputFieldDiv {
  display: flex;
  align-items: center;
}

.inputFieldDiv button {
  margin-left: 10px;
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

.forgotPasswordLink {
  display: block;
  margin-top: 10px;
  text-align: right;
  color: #00cc66;
}

.submitBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.submitText {
  font-size: 18px;
}

.submitButton {
  padding: 10px 20px;
  background-color: #00cc66;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.registerLink {
  display: block;
  margin-top: 15px;
  text-align: center;
  color: #00cc66;
}
.requestsList {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
}

.requestsList h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.requestsList ul {
  list-style-type: none;
  padding: 0;
}

.requestsList li {
  background-color: #fff;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.requestsList p {
  margin: 5px 0;
}

.requestsList strong {
  color: #555;
}

/* Container for the button */
.viewRequestsContainer {
  text-align: center; /* Center the button */
  margin-top: 20px; /* Space above the button */
}

/* Button styles */
.viewRequestsButton {
  display: inline-block; /* Make the button inline */
  text-decoration: none; /* Remove underline from the link */
}

.viewRequestsButton button {
  background-color: #00cc66; /* Blue background */
  border: none;
  border-radius: 4px;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
